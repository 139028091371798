<template>
  <div class="container">
    <div class="tip-box">
      加盟店 決済成功画面 <br />
      シミュレーション用
    </div>
    <div class="header">
      <van-icon name="passed" />
      <h4>Payment successful!</h4>
      <p>We have recieved your payment</p>
    </div>
  </div>
</template>

<script>
import { openWebGet } from '@/apis'
import { isMobile } from '@/utils'

export default {
  name: 'Confirmation',
  data() {
    return {
      data: {
        currencyType: '',
        orderPrice: '',
      },
    }
  },
  created() {
    // this.getConsultPayment()
    if (isMobile) {
      this.$router.replace('/m/success')
    }
  },
  methods: {
    getConsultPayment() {
      const clientOrderNo = this.$route.query.clientOrderNo
      // localStorage.getItem('clientOrderNo') || ''
      if (clientOrderNo === '') {
        this.renderFailedUrl()
      }
      openWebGet({ clientOrderNo }).then((data) => {
        this.data = data
        try {
          // console.log('type' + this.resultType)
          console.log('orderStatus' + this.data.orderStatus)
          if (!this.data || this.data.orderStatus !== 1) {
            this.renderFailedUrl()
          }
        } catch (e) {
          this.renderFailedUrl()
        }
      })
    },
    renderFailedUrl() {
      if (isMobile) {
        this.$router.replace('/m/fail')
      }
      this.$router.replace('/fail')
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  width: 1080px;
  margin: 60px auto;
  padding: 40px 0;
  background: #fff;
}
.header {
  text-align: center;
  .van-icon {
    font-size: 60px;
    color: #010101;
  }
  h4 {
    margin: 10px 0;
    font-size: 26px;
  }
}
.van-button {
  width: 80%;
  margin: 40px auto;
}
</style>
